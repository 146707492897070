import React, { useMemo, useEffect, useCallback, useState } from "react";
import { Stack, Box, Button, Tooltip, Tabs } from "@mui/material";
import useInputParamsInfo from "../hooks/useInputParamsInfo";
import useDxFetch from "../hooks/useDxFetch";
import { useDxData } from "../components/context/DxDataContext";
import { useInputParams } from "../components/context/InputParamsContext";
import PersonalInfoForm from "../components/forms/PersonalInfoForm";
import KeyWordSearchForm from "../components/forms/KeyWordSearchForm";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DxResultGrid from "../components/cards/DXResultGrid";
import RefreshOutlined from "@mui/icons-material/RefreshOutlined";
import StyledTab from "../components/tabs/StyledTab";
import TopExams from "./TopExams";

const CONTAINER_WIDTH = 600;
const CONTAINER_HEIGHT = 400;
const PERIMETER = 2 * (CONTAINER_WIDTH + CONTAINER_HEIGHT);

function RawQuery() {
  const { dxData, setDxData } = useDxData();
  const { inputParams, setInputParams } = useInputParams();
  const { loading, error, fetchDxData } = useDxFetch(inputParams);
  const [selectedTab, setSelectedTab] = useState(0);

  const inputParamsInfo = useInputParamsInfo();

  const handleParamsSubmit = (event) => {
    event.preventDefault();
    fetchDxData();
  };

  const setInitialParamsInfo = useCallback(() => {
    setInputParams(
      inputParamsInfo.reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [currentValue.name]: currentValue.defaultValue,
        }),
        {}
      )
    );
  }, [inputParamsInfo, setInputParams]);

  useEffect(() => {
    if (!inputParams) {
      setInitialParamsInfo();
    }
  }, [inputParams, inputParamsInfo, setInitialParamsInfo]);

  const setInputParamsHandler = (paramValue, paramName) => {
    setInputParams((prevParams) => ({
      ...prevParams,
      [paramName]: paramValue,
    }));
  };

  const columnNames = useMemo(() => {
    return dxData?.dx_response ? Object.keys(dxData.dx_response) : [];
  }, [dxData?.dx_response]);

  const rowData = useMemo(() => {
    return dxData?.dx_response ? Object.values(dxData.dx_response) : [];
  }, [dxData?.dx_response]);

  if (error) {
    return <Box>Error: {error.message}</Box>;
  }

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundColor: "transparent",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        py: 4,
        position: "relative",
        paddingTop: "64px",
      }}
    >
      {/* Botón "Reset" */}
      <Box
        sx={{
          position: "absolute",
          top: "16px",
          right: "16px",
          backgroundColor: "#24272d",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: 3,
          transition: "background-color 0.3s",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.25)",
            cursor: "pointer",
          },
        }}
        onClick={() => {
          setInitialParamsInfo();
          setDxData(null);
        }}
      >
        <RefreshOutlined sx={{ fontSize: 20, color: "white" }} />
      </Box>

      {/* Contenedor principal */}
      <Box
        sx={{
          width: { xs: "95%", sm: "80%", md: "600px" },
          maxWidth: "600px",
          backgroundColor: "#24272d",
          backdropFilter: "blur(8px)",
          borderRadius: "16px",
          padding: { xs: 2, sm: 3, md: 4 },
          boxShadow: "0 8px 32px 0 rgba(0,0,0,0.3)",
          marginTop: 2,
          position: "relative",
          zIndex: 100,
        }}
      >
        <Stack direction="column" spacing={3} alignItems="center">
          <Box sx={{ width: "100%" }}>
            <PersonalInfoForm
              inputParams={inputParams}
              setInputParamsHandler={setInputParamsHandler}
            />
          </Box>

          <KeyWordSearchForm
            inputParams={inputParams}
            setInputParamsHandler={setInputParamsHandler}
          />

          <Tooltip
            title={
              inputParams?.sexo && inputParams?.edad
                ? ""
                : "Para realizar la búsqueda debes seleccionar sexo y edad y agregar un cuadro clínico actual con el botón +"
            }
            placement="bottom"
            arrow
          >
            <span>
              <Button
                variant="contained"
                color="error"
                onClick={handleParamsSubmit}
                disabled={
                  loading ||
                  !inputParams ||
                  !inputParams?.sexo ||
                  !inputParams?.edad ||
                  !inputParams["cuadro_actual"] ||
                  !Object.keys(inputParams["cuadro_actual"]).length
                }
                sx={{
                  borderRadius: "8px",
                  paddingX: 3,
                  paddingY: 1.5,
                  fontSize: "1rem",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "red.dark",
                  },
                  width: "100%",
                }}
              >
                <AccountTreeIcon sx={{ pr: 1 }} />
                Buscar
              </Button>
            </span>
          </Tooltip>
        </Stack>

        {loading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              pointerEvents: "none",
              zIndex: 999,
              "& svg rect": {
                strokeDasharray: PERIMETER,
                strokeDashoffset: PERIMETER,
                strokeLinecap: "round",
                // Animación con varios keyframes intermedios para simular aceleración y desaceleración
                animation: "dash 4s ease-in-out infinite",
              },
              "@keyframes dash": {
                "0%": { strokeDashoffset: PERIMETER }, // inicia sin trazo
                "20%": { strokeDashoffset: PERIMETER * 0.5 }, // va acelerando y disminuye offset
                "50%": { strokeDashoffset: 0 }, // trazo completo, llega al punto opuesto lentamente
                "80%": { strokeDashoffset: PERIMETER * 0.5 }, // comienza a retraerse, acelerando al principio
                "100%": { strokeDashoffset: PERIMETER }, // vuelve al inicio, desacelerando al final
              },
            }}
          >
            <svg width="100%" height="100%">
              <rect
                x="1.5"
                y="1.5"
                width="calc(100% - 3px)"
                height="calc(100% - 3px)"
                rx="16"
                ry="16"
                stroke="white"
                strokeWidth="3"
                fill="transparent"
              />
            </svg>
          </Box>
        )}
      </Box>

      {dxData ? (
        <Box
          sx={{
            alignItems: "flex-start",
            width: { xs: "90%", md: "80%" },
            mt: 4,
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            sx={{
              "& .MuiTab-root": {
                color: "white",
                borderRadius: "4px 4px 0 0",
                borderBottom: "2px solid white",
                "&.Mui-selected": {
                  backgroundColor: "background.dark",
                  color: "white",
                  borderBottom: "2px solid white",
                },
                "&:not(.Mui-selected)": {
                  backgroundColor: "background.default",
                  color: "white",
                },
              },
              alignItems: "flex-start",
            }}
          >
            <StyledTab label="DIAGNÓSTICOS DE GRUPO" />
            <StyledTab label="TOP EXÁMENES" />
          </Tabs>
          <Box sx={{ paddingTop: 2 }}>
            {selectedTab === 0 && (
              <DxResultGrid columnNames={columnNames} rowData={rowData} />
            )}
            {selectedTab === 1 && <TopExams examsInfo={dxData?.exams_info} />}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}

export default RawQuery;
